@charset "UTF-8";
/* font-family */
@font-face {
  font-family: "YuGothic-M";
  font-weight: normal;
   src: local("YuGothic-Medium"),
      local("Yu Gothic Medium"),
      local("YuGothic-Regular");
}

@font-face {
  font-family: "YuGothic-M";
  font-weight: bold;
   src: local("YoGothic-Bold"),
      local("Yu Gothic");
}


/* IE10以上 */
// @media all and (-ms-high-contrast: none) {
//   body {
//     font-family: Verdana, Meiryo, sans-serif;
//   }
// }

$rem-baseline: 10px;
@import "base/rem";
html, body {
	height: 100%;
}
html {
  @include rem-baseline;
  @include sp {@include rem-baseline(90%);};
}
body{
  @include pc {font-size: 1.6rem;}/* 16px */
  @include sp {font-size: 1.3rem;}/* 13px */
  font-family: -apple-system, blinkMacSystemFont, YuGothic-M, YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "palt";
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  ::i-block-chrome, body {
    font-feature-settings: "pkna";
  }
}
