@charset "UTF-8";
$keyColor:#000;
// @mixin query($size){
//   @media all and (max-width: $size + px) {
//     @content;
//   }
// }
@mixin deepGreen {
  background-color: #008d7c;
}
@mixin lightGreen {
  background-color: #4ccfba;
}
@mixin yellow {
  background-color: #feca2f;
}
@mixin white {
  background-color: #fff;
}
@mixin bgiUrl($pass) {
  background-image: url($pass);
  background-repeat: no-repeat;
  background-size: cover;
}

$widthSp: 425px; //スマホsizew
$widthPc: 426px;
@mixin sp {
  @media (max-width: $widthSp) {
    @content;
  }
}
@mixin pc {
  @media (min-width: $widthPc) {
    @content;
  }
}
// .box {
//   @include bgiUrl(img/***.png);
//   width: 100%;
// }
// 

//基礎
// @import "https://fonts.googleapis.com/earlyaccess/notosansjapanese.css";
// @import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import "base/_base.scss";
@import "base/_layout.scss";
@import "base/_rem.scss";
@import "base/_reset.scss";

// Parts
@import "parts/_otherParts.scss";

// block
@import "block/_contact.scss";
@import "block/_home.scss";
@import "block/_leftblock.scss";
@import "block/_news.scss";
@import "block/_page.scss";
@import "block/_profile.scss";

// //部品
// @import "elements/member";
// @import "elements/works";
// @import "elements/about";
// @import "elements/news";

