#news{
  text-align: center;
  color: #008d7c;
  overflow: hidden;
  @include white;
  a{
    color: #008d7c;
    line-height: 1.5;
  }
  .top{
    @include deepGreen;
    width: 100%;
    position: relative;
    @include pc {
      margin-bottom: 2.3em;
      height: 25px;
    }
    @include sp {
      margin-bottom: 2.8rem;
      height: 20px;
    }
    span,
    span:before,
    span:after{
      position: absolute;
      display: block;
      border-radius: 6px;
      @include pc {
        width: 12px;
        height: 12px;
      }
      @include sp {
        width: 9px;
        height: 9px;
      }
    }
    span{
      @include lightGreen
      @include pc {
        left: 10px;
        top: 7px;
      }
      @include sp {
        left: 8px;
        top: 6px;
      }
      &:before,
      &:after{
        content: "";
        display: block;
      }
      &:before{
        @include yellow;
        @include pc {left: 20px;}
        @include sp {left: 16px;}
      }
      &:after{
        @include white;
        @include pc {left: 41px;}
        @include sp {left: 34px;}
      }
    }
  }
  h2{
    img{
      vertical-align: top;
      @include sp {height: 16px;}
    }
  }
  h3{
    font-size: 1.4rem;
    letter-spacing: .2em;
    @include pc {margin-bottom: 5rem;}
    @include sp {margin-bottom: 3.6rem;}
    img{
      width: 15px;
      margin-left: 1em;
    }

  }
  ul{
    overflow: hidden;
    display: table;
    margin: auto;
    @include pc {margin-bottom: 1.5rem;}
    @include sp {margin-bottom: 1.4rem;}
    li{
      display: table-cell;
      padding: 4px 8px 0px;
      @include pc {font-size: 1.5rem;}
      @include sp {font-size: 1.3rem;}
      &:nth-child(2){
        border-style: solid;
      　border-color: #008d7c;
        @include pc {border-width: 0px 2px 0px;}
        @include sp {border-width: 0px 1.5px 0px;}
      }
    }
  }
}