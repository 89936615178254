#profile{
  text-align: center;
  color: #008d7c;
  .info{
    font-size: 1.4rem;
    line-height: 1.8em;
    letter-spacing: .2rem;
  }
  .wrap{
    @include pc {
      display: flex;
      margin-top: 7.3rem;
    }
    @include sp {
      margin-top: 3.3rem;
    }
    > div{
      @include pc {
        width: 44.32%;
        height: 354px;
      }
      @include sp {
        height: 347px;
      }
    }
    .profile-tab{    
      position: relative;  
      overflow: hidden;
      @include white;
      @include sp {margin-bottom: 15px;}
      >div{
        position: absolute;
        top: 0;
        width: 100%;
        transition: all 300ms 0s ease;
        cursor: pointer;
      }
      .default{
        right: 0;
        height: 100%;
        p{
          padding-top: 150px;
          img{
            max-width: 122px;
          }
        }
      }
      .change{
        font-size: 14px;
        padding: 40px 50px;
        right: -294px;
        dl{
          text-align: center;
          letter-spacing: 0.1em;
          @include pc {dt,dd{line-height: 2.3em;}}
          @include sp {dt,dd{line-height: 2em;}}
          .illust{
            margin-bottom: 20px;
            img{width: 75px;}
          }
        }
      }
      .btn{
        position: absolute;
        width: 100%;
        bottom: 2rem;
        li{
          font-size: 1rem;
          width: 15px;
          color: #aaeadc;
          display: inline-block;
          cursor: pointer;
          &.on{
            color: #feca2f;
          }
        }
      }
      &:before,
      &:after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
      }
      &:before{
        border-width: 0 26px 26px 0;
        border-color: transparent #aaeadc transparent transparent;
      }
      &:after{
        border-width: 26px 0 0 26px;
        border-color: transparent transparent transparent #008d7c;
      }
    }
    .profile-skill{
      @include pc {margin-left: 11%;}
      color: #fff;
      position: relative;
      @include pc {padding: 4rem 0;}
      @include lightGreen
      .pencil{
        position: relative;
        width: 10%;
        left: 3rem;
        border-bottom: solid 3px #008d7c;
        animation-duration: 6s;
        animation-fill-mode: forwards;
        @include pc {
          height: 40px;
          top: 6.5rem;
        }
        @include sp {
          height: 38px;
          top: 5rem;
        }
        &.pencil_move{
          animation-name: pencilLine;
        }
        &.pencil_remove{
          animation-name: pencilLine_remove;
        }
        @keyframes pencilLine{
          0% {width: 10%;}
          40%{width: 79%;}
          100%{width: 79%;}
        }
        @keyframes pencilLine_remove{
          0% {width: 79%;}
          40%{width: 10%;}
          100%{width: 10%;}
        }
        img{
          position: absolute;
          @include pc {
            max-width: 38px;
            right: -40px;
          }
          @include sp {
            max-width: 36px;
            right: -34px;
          }
        }
      }
      dt{
        @include pc {
          font-size: 1.8rem;
          margin-bottom: 100px;
        }
        @include sp {
          font-size: 1.9rem;
          margin-bottom: 80px;
        }
      }
      dd{
        text-align: left;
        width: 130px;
        margin: auto;
        @include pc {margin-bottom: 2em;}        
        @include sp {
          font-size: 1.8rem;
          margin-bottom: 2.5em;
        }
        &:before{
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 6px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 1em;
          @include deepGreen
        }
      }
    }
  }
}