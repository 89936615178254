body{
  background-color: #aaeadc;
}
.block{
  width: 90%;
  max-width: 670px;
  margin: auto;
  margin-bottom: 86px;
}

.en_Med{
  font-family: AvenirNextLTPro-Medium;
  font-weight: normal;
  font-style: normal;
  letter-spacing: .2rem;
}
.en_Reg{
  font-family: AvenirNextLTPro-Regular;
  font-weight: normal;
  font-style: normal;
  letter-spacing: .2rem;
}
h2{
  @include pc {
    font-size: 2.1rem;
    margin-bottom: 2.5rem;
  }
  @include sp {
    font-size: 2.1rem;
    margin-bottom: 3.4rem;
  }
}
