#contact{
  // background-image: url('../img/footer.png');
  @include deepGreen;
  background-image: radial-gradient(#007050 15%, transparent 0), radial-gradient(#007050 15%, transparent 0);
  background-position: 0 0, 4px 4px;
  background-size: 8px 8px;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  @include pc {padding: 90px 40px 40px 40px;}
  @include sp {padding: 45px 20px 20px;}
  .copy{
    @include pc {font-size: 1rem;}
    // @include sp {font-size: .1rem;}
    text-align: left; 
  }
  .patapata{
    @include pc { margin: 4rem 0 5rem;}
    @include sp { margin: 3.5rem 0 4rem;}
    img{
      @include pc {
        max-width: 108px;
        margin-right: 70px;
      }
      @include sp {
        max-width: 82px;
        margin-right: 50px;
      }
    }
  }
  .mail{
    @include pc {margin-bottom: 4rem;}
    @include sp {margin-bottom: 7rem;}
  }
}