#home{
  position: relative;
  color: #fff;
  height: 412px;
  overflow: hidden;
  @include deepGreen;
  .dot{
    height: 412px;
    width: 100%;
    position: absolute;
    background-position: 0 0,3px 3px;
    background-size: 6px 6px;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
  }
  .firstswitch{
    background-image: radial-gradient(#4ccfba 0%,transparent 0),radial-gradient(#4ccfba 20%,transparent 0);
    animation-name: firstswitch;
  }
  .secondswitch{
    background-image: radial-gradient(#4ccfba 20%,transparent 0),radial-gradient(#4ccfba 0%,transparent 0);
    animation-name: secondswitch;
    opacity: 0.4;
  }
  @keyframes firstswitch{
    0% {opacity: 0.4;}
    50%{opacity: 1;}
  }
  @keyframes secondswitch{
    0% {opacity: 1;}
    50%{opacity: 0.4;}
  }
  h2{
    font-size: 1.2rem;
    position: absolute;
    @include pc {padding: 5% 5%;}
    @include sp {padding: 9% 9%;}
  }
  h1{
    font-size: 2.6rem;
    letter-spacing: .4rem;
    width: 100%;
    height: 25px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
  }
  .frame{
    // transition: all 200ms 0s ease;
    position: absolute;
    width: 160%;
    height: 100%;
    left: -30%;
    min-width: 670px;
    @include pc {background: repeating-linear-gradient(-64deg,#008d7c,#008d7c 20px,transparent 0,transparent 40px);}
    @include sp {background: repeating-linear-gradient(-64deg,#008d7c,#008d7c 20px,transparent 0,transparent 40px);}
  }
}