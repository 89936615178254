@charset "UTF-8";
/* font-family */
@font-face {
  font-family: "YuGothic-M";
  font-weight: normal;
  src: local("YuGothic-Medium"), local("Yu Gothic Medium"), local("YuGothic-Regular"); }

@font-face {
  font-family: "YuGothic-M";
  font-weight: bold;
  src: local("YoGothic-Bold"), local("Yu Gothic"); }

/* IE10以上 */
html, body {
  height: 100%; }

html {
  font-size: 62.5%; }
  @media (max-width: 425px) {
    html {
      font-size: 56.25%; } }

body {
  /* 16px */
  /* 13px */
  font-family: -apple-system, blinkMacSystemFont, YuGothic-M, YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "palt"; }
  @media (min-width: 426px) {
    body {
      font-size: 1.6rem; } }
  @media (max-width: 425px) {
    body {
      font-size: 1.3rem; } }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ::i-block-chrome, body {
    font-feature-settings: "pkna"; } }

::-webkit-scrollbar {
  display: none; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  font-weight: normal; }

body {
  line-height: 1; }

article, aside, dialog, figure, footer, header,
hgroup, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

::selection {
  background: rgba(255, 255, 255, 0); }

:focus {
  outline: none; }

a, a img, button {
  outline: none;
  border: none;
  cursor: pointer; }

img {
  vertical-align: bottom;
  max-width: 100%; }

a:hover, button:hover {
  outline: none; }

a:disabled, button:disabled, input:disabled {
  pointer-events: none; }

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  list-style: none;
  background-repeat: no-repeat;
  box-sizing: border-box;
  vertical-align: inherit;
  vertical-align: bottom; }

body {
  background-color: #aaeadc; }

.block {
  width: 90%;
  max-width: 670px;
  margin: auto;
  margin-bottom: 86px; }

.en_Med {
  font-family: AvenirNextLTPro-Medium;
  font-weight: normal;
  font-style: normal;
  letter-spacing: .2rem; }

.en_Reg {
  font-family: AvenirNextLTPro-Regular;
  font-weight: normal;
  font-style: normal;
  letter-spacing: .2rem; }

@media (min-width: 426px) {
  h2 {
    font-size: 2.1rem;
    margin-bottom: 2.5rem; } }

@media (max-width: 425px) {
  h2 {
    font-size: 2.1rem;
    margin-bottom: 3.4rem; } }

#contact {
  background-color: #008d7c;
  background-image: radial-gradient(#007050 15%, transparent 0), radial-gradient(#007050 15%, transparent 0);
  background-position: 0 0, 4px 4px;
  background-size: 8px 8px;
  color: #fff;
  text-align: center;
  box-sizing: border-box; }
  @media (min-width: 426px) {
    #contact {
      padding: 90px 40px 40px 40px; } }
  @media (max-width: 425px) {
    #contact {
      padding: 45px 20px 20px; } }
  #contact .copy {
    text-align: left; }
    @media (min-width: 426px) {
      #contact .copy {
        font-size: 1rem; } }
  @media (min-width: 426px) {
    #contact .patapata {
      margin: 4rem 0 5rem; } }
  @media (max-width: 425px) {
    #contact .patapata {
      margin: 3.5rem 0 4rem; } }
  @media (min-width: 426px) {
    #contact .patapata img {
      max-width: 108px;
      margin-right: 70px; } }
  @media (max-width: 425px) {
    #contact .patapata img {
      max-width: 82px;
      margin-right: 50px; } }
  @media (min-width: 426px) {
    #contact .mail {
      margin-bottom: 4rem; } }
  @media (max-width: 425px) {
    #contact .mail {
      margin-bottom: 7rem; } }

#home {
  position: relative;
  color: #fff;
  height: 412px;
  overflow: hidden;
  background-color: #008d7c; }
  #home .dot {
    height: 412px;
    width: 100%;
    position: absolute;
    background-position: 0 0,3px 3px;
    background-size: 6px 6px;
    animation-duration: 0.8s;
    animation-iteration-count: infinite; }
  #home .firstswitch {
    background-image: radial-gradient(#4ccfba 0%, transparent 0), radial-gradient(#4ccfba 20%, transparent 0);
    animation-name: firstswitch; }
  #home .secondswitch {
    background-image: radial-gradient(#4ccfba 20%, transparent 0), radial-gradient(#4ccfba 0%, transparent 0);
    animation-name: secondswitch;
    opacity: 0.4; }

@keyframes firstswitch {
  0% {
    opacity: 0.4; }
  50% {
    opacity: 1; } }

@keyframes secondswitch {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.4; } }
  #home h2 {
    font-size: 1.2rem;
    position: absolute; }
    @media (min-width: 426px) {
      #home h2 {
        padding: 5% 5%; } }
    @media (max-width: 425px) {
      #home h2 {
        padding: 9% 9%; } }
  #home h1 {
    font-size: 2.6rem;
    letter-spacing: .4rem;
    width: 100%;
    height: 25px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center; }
  #home .frame {
    position: absolute;
    width: 160%;
    height: 100%;
    left: -30%;
    min-width: 670px; }
    @media (min-width: 426px) {
      #home .frame {
        background: repeating-linear-gradient(-64deg, #008d7c, #008d7c 20px, transparent 0, transparent 40px); } }
    @media (max-width: 425px) {
      #home .frame {
        background: repeating-linear-gradient(-64deg, #008d7c, #008d7c 20px, transparent 0, transparent 40px); } }

#leftblock {
  position: fixed;
  margin: auto;
  z-index: 1; }
  @media (min-width: 426px) {
    #leftblock {
      margin-left: 1%; } }
  @media (max-width: 425px) {
    #leftblock {
      top: 25px;
      left: 5%; } }
  #leftblock li {
    position: absolute;
    background-color: #4ccfba; }
    @media (min-width: 426px) {
      #leftblock li {
        width: 21px;
        transition: all 1000ms 0s ease; } }
    @media (max-width: 425px) {
      #leftblock li {
        width: 12px;
        transition: all 500ms 0s ease; } }
    #leftblock li.ya {
      top: 0;
      left: 0; }
    #leftblock li.shi {
      top: 0; }
      @media (min-width: 426px) {
        #leftblock li.shi {
          left: 21px; } }
      @media (max-width: 425px) {
        #leftblock li.shi {
          left: 12px; } }
    #leftblock li.ba {
      left: 0; }
      @media (min-width: 426px) {
        #leftblock li.ba {
          top: 21px; } }
      @media (max-width: 425px) {
        #leftblock li.ba {
          top: 12px; } }
    @media (min-width: 426px) {
      #leftblock li.ko {
        left: 21px; } }
    @media (max-width: 425px) {
      #leftblock li.ko {
        left: 12px; } }
    @media (min-width: 426px) {
      #leftblock li.ko {
        top: 21px; } }
    @media (max-width: 425px) {
      #leftblock li.ko {
        top: 12px; } }
  #leftblock.news.ya {
    top: 0;
    left: 0; }
  @media (min-width: 426px) {
    #leftblock.news .shi {
      top: 21px; } }
  @media (max-width: 425px) {
    #leftblock.news .shi {
      top: 12px; } }
  @media (min-width: 426px) {
    #leftblock.news .shi {
      left: 21px; } }
  @media (max-width: 425px) {
    #leftblock.news .shi {
      left: 12px; } }
  #leftblock.news .ba {
    left: 0; }
    @media (min-width: 426px) {
      #leftblock.news .ba {
        top: 42px; } }
    @media (max-width: 425px) {
      #leftblock.news .ba {
        top: 24px; } }
  @media (min-width: 426px) {
    #leftblock.news .ko {
      top: 63px; } }
  @media (max-width: 425px) {
    #leftblock.news .ko {
      top: 36px; } }
  @media (min-width: 426px) {
    #leftblock.news .ko {
      left: 21px; } }
  @media (max-width: 425px) {
    #leftblock.news .ko {
      left: 12px; } }
  #leftblock.profile .ya {
    top: 0; }
    @media (min-width: 426px) {
      #leftblock.profile .ya {
        left: 21px; } }
    @media (max-width: 425px) {
      #leftblock.profile .ya {
        left: 12px; } }
  #leftblock.profile .shi {
    left: 0; }
    @media (min-width: 426px) {
      #leftblock.profile .shi {
        top: 21px; } }
    @media (max-width: 425px) {
      #leftblock.profile .shi {
        top: 12px; } }
  #leftblock.profile .ba {
    left: 0; }
    @media (min-width: 426px) {
      #leftblock.profile .ba {
        top: 42px; } }
    @media (max-width: 425px) {
      #leftblock.profile .ba {
        top: 24px; } }
  @media (min-width: 426px) {
    #leftblock.profile .ko {
      top: 42px; } }
  @media (max-width: 425px) {
    #leftblock.profile .ko {
      top: 24px; } }
  @media (min-width: 426px) {
    #leftblock.profile .ko {
      left: 21px; } }
  @media (max-width: 425px) {
    #leftblock.profile .ko {
      left: 12px; } }
  #leftblock.contact .ya {
    top: 0;
    left: 0; }
  #leftblock.contact .shi {
    left: 0; }
    @media (min-width: 426px) {
      #leftblock.contact .shi {
        top: 21px; } }
    @media (max-width: 425px) {
      #leftblock.contact .shi {
        top: 12px; } }
  #leftblock.contact .ba {
    left: 0; }
    @media (min-width: 426px) {
      #leftblock.contact .ba {
        top: 42px; } }
    @media (max-width: 425px) {
      #leftblock.contact .ba {
        top: 24px; } }
  #leftblock.contact .ko {
    left: 0; }
    @media (min-width: 426px) {
      #leftblock.contact .ko {
        top: 63px; } }
    @media (max-width: 425px) {
      #leftblock.contact .ko {
        top: 36px; } }

#news {
  text-align: center;
  color: #008d7c;
  overflow: hidden;
  background-color: #fff; }
  #news a {
    color: #008d7c;
    line-height: 1.5; }
  #news .top {
    background-color: #008d7c;
    width: 100%;
    position: relative; }
    @media (min-width: 426px) {
      #news .top {
        margin-bottom: 2.3em;
        height: 25px; } }
    @media (max-width: 425px) {
      #news .top {
        margin-bottom: 2.8rem;
        height: 20px; } }
    #news .top span,
    #news .top span:before,
    #news .top span:after {
      position: absolute;
      display: block;
      border-radius: 6px; }
      @media (min-width: 426px) {
        #news .top span,
        #news .top span:before,
        #news .top span:after {
          width: 12px;
          height: 12px; } }
      @media (max-width: 425px) {
        #news .top span,
        #news .top span:before,
        #news .top span:after {
          width: 9px;
          height: 9px; } }
    #news .top span {
      background-color: #4ccfba; }
      @media (min-width: 426px) {
        #news .top span {
          left: 10px;
          top: 7px; } }
      @media (max-width: 425px) {
        #news .top span {
          left: 8px;
          top: 6px; } }
      #news .top span:before, #news .top span:after {
        content: "";
        display: block; }
      #news .top span:before {
        background-color: #feca2f; }
        @media (min-width: 426px) {
          #news .top span:before {
            left: 20px; } }
        @media (max-width: 425px) {
          #news .top span:before {
            left: 16px; } }
      #news .top span:after {
        background-color: #fff; }
        @media (min-width: 426px) {
          #news .top span:after {
            left: 41px; } }
        @media (max-width: 425px) {
          #news .top span:after {
            left: 34px; } }
  #news h2 img {
    vertical-align: top; }
    @media (max-width: 425px) {
      #news h2 img {
        height: 16px; } }
  #news h3 {
    font-size: 1.4rem;
    letter-spacing: .2em; }
    @media (min-width: 426px) {
      #news h3 {
        margin-bottom: 5rem; } }
    @media (max-width: 425px) {
      #news h3 {
        margin-bottom: 3.6rem; } }
    #news h3 img {
      width: 15px;
      margin-left: 1em; }
  #news ul {
    overflow: hidden;
    display: table;
    margin: auto; }
    @media (min-width: 426px) {
      #news ul {
        margin-bottom: 1.5rem; } }
    @media (max-width: 425px) {
      #news ul {
        margin-bottom: 1.4rem; } }
    #news ul li {
      display: table-cell;
      padding: 4px 8px 0px; }
      @media (min-width: 426px) {
        #news ul li {
          font-size: 1.5rem; } }
      @media (max-width: 425px) {
        #news ul li {
          font-size: 1.3rem; } }
      #news ul li:nth-child(2) {
        border-style: solid;
        　border-color: #008d7c; }
        @media (min-width: 426px) {
          #news ul li:nth-child(2) {
            border-width: 0px 2px 0px; } }
        @media (max-width: 425px) {
          #news ul li:nth-child(2) {
            border-width: 0px 1.5px 0px; } }

.page-wrap {
  position: relative;
  max-width: 890px;
  margin: auto; }
  @media (min-width: 426px) {
    .page-wrap {
      padding-top: 11rem; } }
  @media (max-width: 425px) {
    .page-wrap {
      padding-top: calc(15.3rem/2); } }

#profile {
  text-align: center;
  color: #008d7c; }
  #profile .info {
    font-size: 1.4rem;
    line-height: 1.8em;
    letter-spacing: .2rem; }
  @media (min-width: 426px) {
    #profile .wrap {
      display: flex;
      margin-top: 7.3rem; } }
  @media (max-width: 425px) {
    #profile .wrap {
      margin-top: 3.3rem; } }
  @media (min-width: 426px) {
    #profile .wrap > div {
      width: 44.32%;
      height: 354px; } }
  @media (max-width: 425px) {
    #profile .wrap > div {
      height: 347px; } }
  #profile .wrap .profile-tab {
    position: relative;
    overflow: hidden;
    background-color: #fff; }
    @media (max-width: 425px) {
      #profile .wrap .profile-tab {
        margin-bottom: 15px; } }
    #profile .wrap .profile-tab > div {
      position: absolute;
      top: 0;
      width: 100%;
      transition: all 300ms 0s ease;
      cursor: pointer; }
    #profile .wrap .profile-tab .default {
      right: 0;
      height: 100%; }
      #profile .wrap .profile-tab .default p {
        padding-top: 150px; }
        #profile .wrap .profile-tab .default p img {
          max-width: 122px; }
    #profile .wrap .profile-tab .change {
      font-size: 14px;
      padding: 40px 50px;
      right: -294px; }
      #profile .wrap .profile-tab .change dl {
        text-align: center;
        letter-spacing: 0.1em; }
        @media (min-width: 426px) {
          #profile .wrap .profile-tab .change dl dt, #profile .wrap .profile-tab .change dl dd {
            line-height: 2.3em; } }
        @media (max-width: 425px) {
          #profile .wrap .profile-tab .change dl dt, #profile .wrap .profile-tab .change dl dd {
            line-height: 2em; } }
        #profile .wrap .profile-tab .change dl .illust {
          margin-bottom: 20px; }
          #profile .wrap .profile-tab .change dl .illust img {
            width: 75px; }
    #profile .wrap .profile-tab .btn {
      position: absolute;
      width: 100%;
      bottom: 2rem; }
      #profile .wrap .profile-tab .btn li {
        font-size: 1rem;
        width: 15px;
        color: #aaeadc;
        display: inline-block;
        cursor: pointer; }
        #profile .wrap .profile-tab .btn li.on {
          color: #feca2f; }
    #profile .wrap .profile-tab:before, #profile .wrap .profile-tab:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid; }
    #profile .wrap .profile-tab:before {
      border-width: 0 26px 26px 0;
      border-color: transparent #aaeadc transparent transparent; }
    #profile .wrap .profile-tab:after {
      border-width: 26px 0 0 26px;
      border-color: transparent transparent transparent #008d7c; }
  #profile .wrap .profile-skill {
    color: #fff;
    position: relative;
    background-color: #4ccfba; }
    @media (min-width: 426px) {
      #profile .wrap .profile-skill {
        margin-left: 11%; } }
    @media (min-width: 426px) {
      #profile .wrap .profile-skill {
        padding: 4rem 0; } }
    #profile .wrap .profile-skill .pencil {
      position: relative;
      width: 10%;
      left: 3rem;
      border-bottom: solid 3px #008d7c;
      animation-duration: 6s;
      animation-fill-mode: forwards; }
      @media (min-width: 426px) {
        #profile .wrap .profile-skill .pencil {
          height: 40px;
          top: 6.5rem; } }
      @media (max-width: 425px) {
        #profile .wrap .profile-skill .pencil {
          height: 38px;
          top: 5rem; } }
      #profile .wrap .profile-skill .pencil.pencil_move {
        animation-name: pencilLine; }
      #profile .wrap .profile-skill .pencil.pencil_remove {
        animation-name: pencilLine_remove; }

@keyframes pencilLine {
  0% {
    width: 10%; }
  40% {
    width: 79%; }
  100% {
    width: 79%; } }

@keyframes pencilLine_remove {
  0% {
    width: 79%; }
  40% {
    width: 10%; }
  100% {
    width: 10%; } }
      #profile .wrap .profile-skill .pencil img {
        position: absolute; }
        @media (min-width: 426px) {
          #profile .wrap .profile-skill .pencil img {
            max-width: 38px;
            right: -40px; } }
        @media (max-width: 425px) {
          #profile .wrap .profile-skill .pencil img {
            max-width: 36px;
            right: -34px; } }
    @media (min-width: 426px) {
      #profile .wrap .profile-skill dt {
        font-size: 1.8rem;
        margin-bottom: 100px; } }
    @media (max-width: 425px) {
      #profile .wrap .profile-skill dt {
        font-size: 1.9rem;
        margin-bottom: 80px; } }
    #profile .wrap .profile-skill dd {
      text-align: left;
      width: 130px;
      margin: auto; }
      @media (min-width: 426px) {
        #profile .wrap .profile-skill dd {
          margin-bottom: 2em; } }
      @media (max-width: 425px) {
        #profile .wrap .profile-skill dd {
          font-size: 1.8rem;
          margin-bottom: 2.5em; } }
      #profile .wrap .profile-skill dd:before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 6px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1em;
        background-color: #008d7c; }
