$box_pc: 21px;
$box_sp: 12px;
@mixin box_left{
  @include pc {left: $box_pc;}
  @include sp {left: $box_sp;}
}
@mixin box_top{
  @include pc {top: $box_pc;}
  @include sp {top: $box_sp;}
}
@mixin box_top02{
  @include pc {top: $box_pc*2;}
  @include sp {top: $box_sp*2;}
}
@mixin box_top03{
  @include pc {top: $box_pc*3;}
  @include sp {top: $box_sp*3;}
}
#leftblock{
  position: fixed;
  margin: auto;
  z-index: 1;
  @include pc {margin-left: 1%;}
  @include sp {
    top: 25px;
    left: 5%;
  }
  li{
    position: absolute;
    @include pc {
      width: $box_pc;
      transition: all 1000ms 0s ease;
    }
    @include sp {
      width: $box_sp;
      transition: all 500ms 0s ease;
    }
    @include lightGreen;
    &.ya{
      top: 0;
      left: 0;
    }
    &.shi{
      top: 0;
      @include box_left
    }
    &.ba{
      @include box_top
      left: 0;
    }
    &.ko{
      @include box_left
      @include box_top
    }
  }
  &.news{
    &.ya{
      top: 0;
      left: 0;
    }
    .shi{
      @include box_top
      @include box_left
    }
    .ba{
      @include box_top02
      left: 0;
    }
    .ko{
      @include box_top03
      @include box_left
    }
  }
  &.profile{
    .ya{
      top: 0;
      @include box_left
    }
    .shi{
      @include box_top
      left: 0;
    }
    .ba{
      @include box_top02
      left: 0;
    }
    .ko{
      @include box_top02
      @include box_left
    }
  }
    &.contact{
    .ya{
      top: 0;
      left: 0;
    }
    .shi{
      @include box_top
      left: 0;
    }
    .ba{
      @include box_top02
      left: 0;
    }
    .ko{
      @include box_top03
      left: 0;
    }
  }
}